import React from 'react';
import { ServiceIcon, ExtendIcon, BuildIcon } from '../icons';

import awsImg from '../images/technology-tools/aws.svg';
import nodeJsImg from '../images/technology-tools/node-js.svg';
import reactImg from '../images/technology-tools/react.svg';
import reduxImg from '../images/technology-tools/redux.svg';
import nextImg from '../images/technology-tools/next-js.svg';
import gatsbyImg from '../images/technology-tools/gatsby.svg';
import puppeteerImg from '../images/technology-tools/puppeteer.svg';
import wordpressImg from '../images/technology-tools/wordpress.svg';
import shopify from '../images/technology-tools/shopify.png';
import azure from '../images/technology-tools/azure.png';
import dynamodb from '../images/technology-tools/dynamodb.png';
import es6 from '../images/technology-tools/es6.png';
import kubernetes from '../images/technology-tools/kubernetes.svg';
import docker from '../images/technology-tools/docker.png';
import postgresql from '../images/technology-tools/Postgresql_elephant.svg.png';
import mongo from '../images/technology-tools/mongodb.png';
import mysql from '../images/technology-tools/Mysql_logo.png';
import python from '../images/technology-tools/python-logo.png';
import tailwind from '../images/technology-tools/tailwind-css.png';
import angular from '../images/technology-tools/angular.png';
import terraform from '../images/technology-tools/terraform.svg';
import web3 from '../images/technology-tools/web3.png';
import nlp from '../images/technology-tools/nlp.png';
import scikit from '../images/technology-tools/scikit.png';
import opencv from '../images/technology-tools/opencv.png';
import tensorflow from '../images/technology-tools/tensorflow.png';

import leadershipImg from '../images/career/leadership.svg';
import inclusionImg from '../images/career/inclusion-and-diversity.svg';
import socialImg from '../images/career/social-impact.svg';
import perkImg1 from '../images/career/perk-1.svg';
import perkImg2 from '../images/career/perk-2.svg';
import perkImg3 from '../images/career/perk-3.svg';
import perkImg4 from '../images/career/perk-4.svg';
import perkImg5 from '../images/career/perk-5.svg';
import perkImg6 from '../images/career/perk-6.svg';
import ideaImg from '../images/expertise/idea.svg';
import serviceImg from '../images/expertise/service.svg';
import teamImg from '../images/expertise/team.svg';

import teamAug from '../images/home/team-augmentation.png';
import applicationDevelopment from '../images/home/application-development.png';
import managedItServices from '../images/home/managed-it-services.png';
import cloudServices from '../images/home/cloud-services.png';
import aiMlSolution from '../images/home/aiml-solution.png';
import digitalProduct from '../images/home/digital-product-design-and-development.png';
import digitalStrategy from '../images/home/digital-strategy-and-innovation.png';
import blockchain from '../images/home/blockchain-and-nft.png';

import subi from '../images/clients/subi.png';
import seeda from '../images/clients/seeda.svg';
import pma from '../images/clients/pma.png';
import aurae from '../images/clients/aurae.png';
import clubSwan from '../images/clients/clubSwan.png';
import surfingOnAcloud from '../images/clients/surfing-on-a-cloud.png';
import spenny from '../images/clients/spenny.png';
import livingTurf from '../images/clients/turf.png';
import site360 from '../images/clients/site-360-Logo-reverse-1.svg';
import nimbly from '../images/clients/nimbly.svg';

export const technologies = [
  { name: 'ES6', src: es6, category: 'JavaScript' },
  { name: 'React', src: reactImg, category: 'JavaScript' },
  { name: 'Next.js', src: nextImg, category: 'JavaScript' },
  { name: 'Angular', src: angular, category: 'JavaScript' },
  { name: 'Redux', src: reduxImg, category: 'JavaScript' },
  { name: 'Tailwind CSS', src: tailwind, category: 'JavaScript' },
  { name: 'Node.js', src: nodeJsImg, category: 'JavaScript' },
  { name: 'Python', src: python, category: 'Python' },
  { name: 'WordPress', src: wordpressImg, category: 'Other' },
  { name: 'Shopify', src: shopify, category: 'Other' },
  { name: 'MySQL', src: mysql, category: 'Database' },
  { name: 'MongoDB', src: mongo, category: 'Database' },
  { name: 'DynamoDB', src: dynamodb, category: 'Database' },
  { name: 'PostgreSQL', src: postgresql, category: 'Database' },
  { name: 'AWS', src: awsImg, category: 'Cloud' },
  { name: 'Azure', src: azure, category: 'Cloud' },
  { name: 'Docker', src: docker, category: 'Cloud' },
  { name: 'Kubernetes', src: kubernetes, category: 'Cloud' },
  { name: 'terraform', src: terraform, category: 'Cloud' },
  { name: 'Web3', src: web3, category: 'Other' },
  { name: 'scikit', src: scikit, category: 'ai-ml' },
  { name: 'opencv', src: opencv, category: 'ai-ml' },
  { name: 'tensorflow', src: tensorflow, category: 'ai-ml' },
  { name: 'nlp', src: nlp, category: 'ai-ml' },
];
export const techLanguages = [
  es6,
  reactImg,
  nextImg,
  angular,
  nodeJsImg,
  python,
  shopify,
  awsImg,
  azure,
  web3,
];
export const clients = [
  subi,
  seeda,
  pma,
  aurae,
  clubSwan,
  surfingOnAcloud,
  spenny,
  livingTurf,
  site360,
  nimbly,
];

export const features = [
  {
    title: 'Extend your team',
    content: `High-standard team augmentation process to enhance IT capability and productivity.`,
    linkLabel: 'Help me expand my growth',
    link: '/contact/',
    icon: <ExtendIcon />,
  },
  {
    title: 'Customized Services',
    content: `Software development and MVP development services to meet your strategic needs.`,
    linkLabel: 'Develop my product',
    link: '/contact/',
    icon: <BuildIcon />,
  },
  {
    title: 'Enterprise-level Support',
    content: `Managed expert support services to ensure optimal performance of your infrastructure.`,
    linkLabel: 'Scale my Business',
    link: '/contact/',
    icon: <ServiceIcon />,
  },
];

export const featuresExpertise = [
  {
    title: 'Extend your team',
    content: `We ground a dynamic ecosystem to empower businesses with talent augmenting. Leverage our exceptional talent pool to build tailor-made mobile or web apps, QA, automation, etc.`,
    linkLabel: 'Help me expand my growth',
    link: '/contact/',
    image: teamImg,
  },
  {
    title: 'Get customized services',
    content: `Our tailored software development services are designed to sharpen your strategic edge. Get well-researched web or mobile solutions built from scratch to meet your unique needs.`,
    linkLabel: 'Develop my product',
    link: '/contact/',
    image: ideaImg,
  },
  {
    title: 'Get Enterprise-Level Support',
    content: `Tackle the complexities of your IT infrastructure with an expert support team. Our managed support services are highly responsive, and results driven.`,
    linkLabel: 'Scale my Business',
    link: '/contact/',
    image: serviceImg,
  },
];

export const businessItems = [
  {
    icon: teamAug,
    label: 'Team Augmentation',
    content: 'Enhance the team with exciting tech talents to scale up.',
    list: ['Team Managed by You', 'Team Managed by Dedicated PM'],
    learnMore: 'Learn More',
    link: '/team-augmentation/',
  },
  {
    icon: applicationDevelopment,
    label: 'Application Development',
    content: 'Get fully functional applications aligned with the goals.',
    list: [
      'Software Architecture',
      'Web Application',
      'Mobile Application',
      'SQA',
    ],
    learnMore: 'Learn More',
    link: '/application-development/',
  },
  {
    icon: cloudServices,
    label: 'Cloud Services',
    content:
      'Gain better accessibility and enhanced security with managed cloud services.',
    list: ['Cloud Strategy', 'Cloud Migration', 'DevOps', 'Security'],
    learnMore: 'Learn More',
    link: '/cloud-service/',
  },
  {
    icon: managedItServices,
    label: 'Managed IT Services',
    content: 'Achieve agility with responsive and expert-managed IT support.',
    list: [
      'Application Support ',
      'IT Help Desk',
      'Backup and Recovery',
      'Device Management',
    ],
    learnMore: 'Learn More',
    link: '/managed-it-services/',
  },
  {
    icon: aiMlSolution,
    label: 'AI/ML Solution',
    content: 'Harness AI to get actionable insights and automate tasks.',
    list: [
      'Data Analysis',
      'Generative AI',
      'Machine Learning',
      'Deep Learning',
    ],
    learnMore: 'Learn More',
    link: '/ai-ml-solution/',
  },
  {
    icon: digitalProduct,
    label: 'Digital Product Design & Development',
    content:
      'Bridging innovation and technology to deliver meaningful solutions.',
    list: [
      'Product Concepting',
      'Technical Architecture',
      'Mobile Solutions',
      'Product Engineering',
    ],
    learnMore: '',
    link: '',
  },
  {
    icon: digitalStrategy,
    label: 'Digital Strategy & Innovation',
    content: 'Result-driven approach to bring game-changing ideas to market.',
    list: [
      'Agile Digital Transformation',
      'Digital Business Models',
      'Concept Ideation',
      'Go-to-market Strategies',
    ],
    learnMore: '',
    link: '',
  },
  {
    icon: blockchain,
    label: 'Blockchain and NFT',
    content: 'Transform and win big with secure and scalable technology.',
    list: ['NFT Development', 'Smart Contract ', 'Custom Blockchain'],
    learnMore: '',
    link: '',
  },
];

export const stats = [
  {
    label: 'Project completed',
    value: '300+',
  },
  {
    label: 'Verticals covered',
    value: '18+',
  },
  {
    label: 'of clients’ costs saved',
    value: '76%',
  },
  {
    label: 'of clients’ time saved',
    value: '87%',
  },
  {
    label: 'Hours team capacity per month',
    value: '76%',
  },
  {
    label: 'Testing services',
    value: '40+',
  },
  {
    label: 'Years of Services',
    value: '10+',
  },
  {
    label: 'Trusted Clients',
    value: '20+',
  },
];

export const industries = [
  {
    label: 'Fintech',
    projectsCompleted: 25,
    link: 'https://example.com/fintech-projects',
  },
  {
    label: 'Lead Management',
    projectsCompleted: 18,
  },
  {
    label: 'e-Commerce',
    projectsCompleted: 30,
  },
  {
    label: 'Real Estate',
    projectsCompleted: 22,
  },
  {
    label: 'Startup',
    projectsCompleted: 15,
  },
  {
    label: 'Healthcare',
    projectsCompleted: 27,
  },
  {
    label: 'Sports',
    projectsCompleted: 12,
  },
  {
    label: 'EdTech',
    projectsCompleted: 20,
    link: 'https://example.com/edtech-projects',
  },
];

export const careerFeatures = [
  {
    title: 'Leadership in action',
    content: `Being under the direct provision of a leader instead of a management team helps our staff get the hands-on experience of proper mentorship and help them grow more efficiently. `,
    image: leadershipImg,
  },
  {
    title: 'Developing community',
    content: `We  understand  everyone is different. Therefore, we have  created  a community where everyone gets an equal scope to contribute, grow, and share one’s idea.`,
    image: inclusionImg,
  },
  {
    title: 'Work-life balance',
    content: `Our   workplace   environment   is   designed   to   be   very   social.   The   balanced   work schedule   lets   everyone   enjoy   work,   communicate   with   each   other,   and   stay productive. `,
    image: socialImg,
  },
];

export const galleryImages = [
  'career/gallery-1.jpg',
  'career/gallery-2.jpg',
  'career/gallery-3.jpg',
  'career/gallery-4.jpg',
  'career/gallery-5.jpg',
];

export const perks = [
  {
    title: 'Competitive compensation',
    image: perkImg1,
  },
  {
    title: 'Healthcare coverage',
    image: perkImg2,
  },
  {
    title: 'Wellness perks',
    image: perkImg3,
  },
  {
    title: 'Paid parental leave',
    image: perkImg4,
  },
  {
    title: 'Employee stock purchase plan',
    image: perkImg5,
  },
  {
    title: 'Learning and development',
    image: perkImg6,
  },
];

export const jobs = [
  {
    title: 'Senior HR Manager',
    posts: 5,
    createdAt: '13th August, 2020',
    deadline: '13th August, 2020',
    type: 'Full Time',
    link: '/',
  },
  {
    title: 'Marketing Executive',
    posts: 3,
    createdAt: '13th August, 2020',
    deadline: '13th August, 2020',
    type: 'Full Time',
    link: '/',
  },
  {
    title: 'Project Coordinator',
    posts: 4,
    createdAt: '13th August, 2020',
    deadline: '13th August, 2020',
    type: 'Full Time',
    link: '/',
  },
];

export const policyData = `<p>Last updated: January 10, 2024</p>
<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
<p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
<h2>Interpretation and Definitions</h2>
<h3>Interpretation</h3>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h3>Definitions</h3>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li>
<p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
</li>
<li>
<p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
</li>
<li>
<p><strong>Application</strong> refers to Quizzy, the software program provided by the Company.</p>
</li>
<li>
<p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Spring Rain Private Ltd, 7th Floor, Commercial Cove, House 56/C , Road 132, Gulshan-1, Dhaka 1212, Bangladesh..</p>
</li>
<li>
<p><strong>Country</strong> refers to: Bangladesh</p>
</li>
<li>
<p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
</li>
<li>
<p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
</li>
<li>
<p><strong>Service</strong> refers to the Application.</p>
</li>
<li>
<p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
</li>
<li>
<p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul>
<h2>Collecting and Using Your Personal Data</h2>
<h3>Types of Data Collected</h3>
<h4>Personal Data</h4>
<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
<ul>
<li>
<p>Email address</p>
</li>
<li>
<p>First name and last name</p>
</li>
<li>
<p>Phone number</p>
</li>
<li>
<p>Usage Data</p>
</li>
</ul>
<h4>Usage Data</h4>
<p>Usage Data is collected automatically when using the Service.</p>
<p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
<p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
<p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
<h3>Use of Your Personal Data</h3>
<p>The Company may use Personal Data for the following purposes:</p>
<ul>
<li>
<p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
</li>
<li>
<p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
</li>
<li>
<p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
</li>
<li>
<p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
</li>
<li>
<p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
</li>
<li>
<p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
</li>
<li>
<p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
</li>
<li>
<p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
</li>
</ul>
<p>We may share Your personal information in the following situations:</p>
<ul>
<li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
<li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
<li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
<li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
<li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
<li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
</ul>
<h3>Retention of Your Personal Data</h3>
<p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
<p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
<h3>Transfer of Your Personal Data</h3>
<p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
<h3>Delete Your Personal Data</h3>
<p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
<p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
<p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
<p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
<h3>Disclosure of Your Personal Data</h3>
<h4>Business Transactions</h4>
<p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
<h4>Law enforcement</h4>
<p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
<h4>Other legal requirements</h4>
<p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
<ul>
<li>Comply with a legal obligation</li>
<li>Protect and defend the rights or property of the Company</li>
<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
<li>Protect the personal safety of Users of the Service or the public</li>
<li>Protect against legal liability</li>
</ul>
<h3>Security of Your Personal Data</h3>
<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
<h2>Children's Privacy</h2>
<p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
<p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
<h2>Links to Other Websites</h2>
<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
<h2>Changes to this Privacy Policy</h2>
<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
<p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy, You can contact us:</p>
<ul>
<li>By email: <a href="mailto:info@springrain.io" style="text-decoration:none" >info@springrain.io</a></li>
</ul>`;
